import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "React.js 入門について発表しました",
  "date": "2017-06-29T07:10:01.000Z",
  "slug": "entry/2017/06/29/161001",
  "tags": ["medley"],
  "hero": "./2017_06_29.png",
  "heroAlt": "react"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`開発本部の楊です。医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」の開発を担当しております。うるさい 5 歳のこどもと一緒に遊ぶのが大好きです。`}</p>
    <p>{`ジョブメドレーの求人機関向け管理画面は React.js を利用し開発しています。
他プロダクトを担当するメンバのなかには React.js について詳しくない人もいる為、TechLunch（開発本部内で定期的に開催している社内技術勉強会）で React.js 入門について話しました。`}</p>
    <h1>{`React.js とは`}</h1>
    <ul>
      <li parentName="ul">{`UI を構築するためのライブラリ`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/Facebook"
        }}>{`Facebook`}</a>{`社が開発しているライブラリでコミュニティも活発で安心して使える`}</li>
      <li parentName="ul">{`多くの`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%B3%A5%F3%A5%DD%A1%BC%A5%CD%A5%F3%A5%C8"
        }}>{`コンポーネント`}</a>{`が提供されており開発が捗る（参照：`}<a parentName="li" {...{
          "href": "https://github.com/brillout/awesome-react-components%EF%BC%89"
        }}>{`https://github.com/brillout/awesome-react-components）`}</a></li>
    </ul>
    <p>{`などなど、様々な特徴があります。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170626/20170626185005.png",
        "alt": null
      }}></img></p>
    <h1>{`TechLunch での発表内容`}</h1>
    <p>{`React.js は UI を構築するためのライブラリなのでデータフローを管理する仕組みは提供していません。
開発の際にはデータフロー管理のために、Flux/Immutable.js などの考え方や技術要素を必要とします。
今回の TechLunch の発表では Flux/Immutable.js についても簡単に触れつつ発表しました。`}</p>
    <p>{`目次はこちら。`}</p>
    <ul>
      <li parentName="ul">{`React.js`}</li>
      <li parentName="ul">{`Flux`}</li>
      <li parentName="ul">{`Immutable.js`}</li>
    </ul>
    <p>{`詳細はこちらをご覧ください。`}</p>
    <h1>{`まとめ`}</h1>
    <ul>
      <li parentName="ul">{`全ては Component、シンプルに保つことを意識する`}</li>
      <li parentName="ul">{`小さく Component を作り、組み立てることにより UI を構築する`}</li>
      <li parentName="ul">{`Flux と併用しデータフローを意識する`}</li>
      <li parentName="ul">{`Immutable.js 便利`}</li>
    </ul>
    <h1>{`最後に`}</h1>
    <p>{`React.js は比較的新しい技術で、仮想 DOM の使用によりブラウザの`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%EC%A5%F3%A5%C0%A5%EA%A5%F3%A5%B0"
      }}>{`レンダリング`}</a>{`が早いなどの利点が多々あります。
必要に応じて新しめの技術も取り入れながら、ジョブメドレーをよりよいプロダクトにしていきたいと思っています。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、ジョブメドレーだけでなく、医師たちがつくるオンライン医療事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」やオンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」、口コミで探せる`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%B2%F0%B8%EE%BB%DC%C0%DF"
      }}>{`介護施設`}</a>{`の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」などのプロダクトも提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p>{`今後ともメドレーを、よろしくお願いいたします！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="CREATOR'S STORY | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fteam%2Fcreator-story.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/team/creator-story.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      